@import '../../../theme/variables.scss';
@import '../../../theme/utils/color.scss';
@import '../../../theme/autoprefixer.scss';

.pinWrapper {
  display: flex;
  flex-direction: column;

  .label {
    font-style: normal;
    font-family: 'Ubuntu-bold', sans-serif;
    font-size: 16px;
    margin-bottom: 5px;
    display: block;
    color: var(--vkit-color-contrast-9);

    @include transition(0.3s);

    .requiredSign {
      color: color(danger, $colorsDefaultMarkupTint);
    }
  }

  .labelError {
    color: color(danger, $colorsDefaultMarkupTint);
  }

  .textHelper {
    font-style: normal;
    font-family: 'Ubuntu-medium', sans-serif;
    font-size: 14px;
    color: color(light, $colorsDefaultMarkupTint);
    height: 27px;
    overflow: hidden;
    display: flex;
    align-items: flex-end;
    @include box-sizing(border-box);
    @include transition(padding 0.3s ease, height 0.3s ease);

    &:empty {
      padding: 0;
      height: 0;
    }
  }

  &::-webkit-scrollbar {
    display: none;
  }

  .pin {
    width: 100%;
    justify-content: space-between;
    display: flex;

    span {
      color: #8997a6;
    }

    .input {
      border: 1px solid color(light, $colorsDefaultMarkupTint);
      border-radius: 5px;
      margin: 4px;
      width: 48px;
      height: 48px;
      padding: 10px;
      font-size: 20px;
      box-sizing: border-box;
      text-align: center;
      color: #017bff;
      font-weight: bold;

      &:disabled {
        border-color: var(--vkit-color-contrast-6);
        background-color: var(--vkit-color-contrast-2);
      }

      &:first-child {
        margin-left: 0;
      }

      &:last-child {
        margin-right: 0;
      }

      &.focus {
        border: 1px solid #017bff;
        box-shadow: 0 0 0 4px rgba(1, 123, 255, 0.1);
      }

      &.error {
        box-shadow: 0 0 0 4px rgba(255, 65, 58, 0.15);
        border: 1px solid #ff413a;
      }
    }
  }
}
