@import '../../theme/autoprefixer';

.tooltip {
  .baseTitle {
    position: relative;
    z-index: 999;
    pointer-events: none;

    .limitTitle {
      @include transform(translateX(-50%));
      display: flex;
      justify-content: center;
      left: 50%;
      min-width: 120px;
      position: absolute;
      width: 100%;
    }
  }

  .title {
    @include border-radius(5px);
    @include box-shadow(0 0 8px var(--vkit-color-blackless));
    @include transition(opacity 0.3s ease, bottom 0.3s ease);
    background-color: var(--vkit-color-contrast);
    border: 1px solid var(--vkit-color-contrast-3);
    bottom: 0;
    color: var(--vkit-color-contrast-9);
    font-size: 0.8rem;
    font-weight: normal;
    opacity: 0;
    padding: 4px;
    position: absolute;
    text-align: center;

    &::before,
    &::after {
      content: '';
      position: absolute;
      right: calc(50% - 10px);
      bottom: -11px;
      border-left: 10px solid transparent;
      border-right: 10px solid transparent;
      border-top: 10px solid var(--vkit-color-contrast-3);
    }

    &::after {
      border-top: 10px solid var(--vkit-color-contrast);
      padding-bottom: 1px;
    }
  }

  &:hover {
    .title {
      opacity: 1;
      bottom: 8px;
    }
  }

  &.alignLeft {
    .baseTitle {
      .limitTitle {
        justify-content: left;
      }
    }

    .title {
      text-align: left;
      &::before,
      &::after {
        right: calc(100% - 28px);
      }
    }
  }

  &.alignRight {
    .baseTitle {
      .limitTitle {
        justify-content: right;
      }
    }

    .title {
      text-align: right;
      &::before,
      &::after {
        right: 28px;
      }
    }
  }

  &.positionBottom {
    display: flex;
    flex-direction: column-reverse;

    .title {
      bottom: auto;
      top: 0;

      &::before,
      &::after {
        top: -11px;
        bottom: auto;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-bottom: 10px solid var(--vkit-color-contrast-3);
        border-top: none;
      }

      &::after {
        border-bottom: 10px solid var(--vkit-color-contrast);
        padding-top: 1px;
      }
    }

    &:hover {
      .title {
        top: 8px;
      }
    }
  }
}
