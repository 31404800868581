.textField {
  &:not(.disabled) {
    input {
      color: #1e222d !important;
    }

    span {
      svg {
        fill: var(--vkit-color-default-1) !important;
      }
    }
  }

  &.disabled {
    [class*='field_'] {
      background: rgba(59, 103, 150, 0.1);
      border: transparent;
    }

    input {
      opacity: 0.5;
    }

    span {
      svg {
        opacity: 0.5;
      }
    }
  }
}
