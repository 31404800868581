@import '../../../theme/variables.scss';
@import '../../../theme/autoprefixer.scss';

.boxInfo {
  margin-bottom: 16px;
  width: 100%;

  &.noBorder {
    [class*='box_box'] {
      background: transparent;
      border: none;
    }
  }

  .content {
    background-color: $colorLightWhiteless;
    border-radius: 6px;

    .title {
      display: flex;
      justify-content: space-between;
      padding: 19px 19px 0 19px;

      @include mobile {
        .progressBar {
          margin-top: 16px;
        }
      }
    }

    .childrenContent {
      padding: 20px;
    }

    &.scroll {
      position: relative;
      overflow: auto;
    }
  }
}

[data-theme='dark'] {
  .content {
    background-color: rgba($colorDarkBlackless, 0.1);
  }
}
