@import '@/theme/variables.scss';
@import '@/theme/autoprefixer.scss';

.dropzone {
  .children {
    pointer-events: none;

    &.pointerEvent {
      * button {
        // allow buttons work inside dropzone
        pointer-events: auto;
      }
    }
  }

  &.pulse {
    @include border-radius(4px);
    animation: pulse 1.5s infinite;

    @keyframes pulse {
      0% {
        -moz-box-shadow: 0 0 0 0 var(--vkit-color-contrast-5);
        box-shadow: 0 0 0 0 var(--vkit-color-contrast-5);
      }

      70% {
        -moz-box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 20px rgba(204, 169, 44, 0);
      }

      100% {
        -moz-box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
        box-shadow: 0 0 0 0 rgba(204, 169, 44, 0);
      }
    }
  }
}
