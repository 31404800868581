@import '../../../theme/main.scss';

.breadcrumb {
  position: relative;
  width: calc(100% - 86px);

  &:before {
    background: linear-gradient(to left, var(--vkit-color-contrast), transparent);
    content: '';
    height: calc(100% + 2px);
    pointer-events: none;
    position: absolute;
    right: 0;
    width: 30px;
    z-index: 2;
  }

  .items {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: flex-start;
    margin-bottom: -16px;
    overflow-x: auto;
    overflow-y: hidden;
    padding-right: 30px;
    position: relative;
    user-select: none;

    .item {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-wrap: nowrap;
      white-space: nowrap;
      cursor: pointer;

      &:first-child {
        .icon {
          display: none;
        }
      }

      & {
        padding-right: 2px;
        color: var(--vkit-color-contrast-9);

        .icon {
          svg {
            fill: var(--vkit-color-contrast-9);
          }
        }
      }

      .icon {
        padding-right: 2px;
      }
    }

    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  @-moz-document url-prefix() {
    .items {
      padding-bottom: 16px;
    }
  }
}
