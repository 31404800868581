$sizes: (
  small: (
    size: 36px,
    text: 10px,
  ),
  medium: (
    size: 56px,
    text: 12px,
  ),
  large: (
    size: 70px,
    text: 16px,
  ),
);

.fileExtensionIcon {
  position: relative;
  display: flex;
  width: auto;
  overflow: hidden;

  .fileExtensionText {
    position: absolute;
    background: var(--vkit-color-contrast-9);
    padding: 1px 3px;
    border-radius: 4px;
    font-size: 8px;
    text-transform: uppercase;
    bottom: 4px;
    color: #fff;
    font-weight: bold;
  }

  @each $key, $values in $sizes {
    &.#{$key} {
      width: map-get($values, size);
      min-width: map-get($values, size);
      height: map-get($values, size);
      .fileExtensionText {
        font-size: map-get($values, text);
        @if ($key == large) {
          bottom: 12px;
        }
        @if ($key == medium) {
          bottom: 10px;
        }
        @if ($key == small) {
          bottom: 6px;
        }
      }
    }
  }
}
