@import '../../../../../../theme/autoprefixer';

.contentList {
  display: table;
  width: 100%;

  .cell {
    @include transition(background 0.3s ease);
    display: table-cell;
    padding: 12px;
    vertical-align: middle;

    $aligns: left, center, right;
    @each $align in $aligns {
      &.#{$align} {
        text-align: $align;
        justify-content: $align;
      }
    }

    &.actions {
      [class^='popover_popover'] {
        margin: -10px 0;
      }
    }
  }

  .header,
  .body .line {
    &:not(:last-child) {
      .cell {
        border-bottom: 1px solid var(--vkit-color-contrast-2);
      }
    }
  }

  .header {
    display: table-header-group;
    position: sticky;
    top: 0;
    background: $colorLightWhiteless;
    z-index: 1;

    .cell {
      font-weight: bold;
      color: var(--vkit-color-default);
      padding: 8px 12px;
      font-size: 0.9rem;

      &:first-child {
        @include border-radius(4px 0 0);
      }

      &:last-child {
        @include border-radius(0 4px 0 0);
      }

      .buttonSort {
        cursor: pointer;
        display: inline-block;
        user-select: none;

        .iconOrder {
          align-items: center;
          display: flex;
          height: 20px;
          position: relative;
          width: 20px;

          .iconOrderNone {
            svg {
              margin: -6px 0;
            }
          }
        }
      }
    }
  }

  .body {
    display: table-row-group;

    .line {
      &:hover {
        .cell {
          background-color: var(--vkit-color-contrast);
        }
      }
    }
  }

  .line {
    display: table-row;
  }
}

[data-theme='dark'] {
  .contentList {
    .header {
      background: var(--vkit-color-contrast);
    }
  }
}
