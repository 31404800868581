@import '../../theme/autoprefixer';

.dataTable {
  &.noElevation {
    [class*='box_box'] {
      background-color: transparent;
      border: none;
    }
  }

  .caption {
    display: flex;
    width: 100%;

    .captionRight:empty,
    .captionLeft:empty {
      display: none;
    }

    .captionRight,
    .captionLeft {
      align-items: center;
      border-bottom: 1px solid var(--vkit-color-contrast-2);
      display: flex;
      flex: 1 1 auto;
      gap: 16px;
      margin: 0 -20px;
      padding: 16px;
    }

    .captionRight {
      justify-content: flex-end;
    }
  }

  .settings {
    border-bottom: 1px solid var(--vkit-color-contrast-2);
  }

  .contentList {
    .lines,
    .boxes {
      display: none;
    }
  }

  .footer {
    border-top: 1px solid var(--vkit-color-contrast-2);
    padding: 16px;
  }

  &.lines {
    .caption {
      margin-top: -20px;
    }

    .settings {
      margin: 0 -20px;
    }

    .contentList {
      margin: 0 -20px -20px;
      width: calc(100% + 40px);
    }
  }

  &.boxes {
    .caption {
      .captionRight,
      .captionLeft {
        border-bottom: none;
        margin-top: -20px;
      }
    }

    .settings {
      @include border-radius(8px);
      border: 2px solid var(--vkit-color-contrast-2);
      margin-bottom: 16px;
    }

    .footer {
      padding: 16px 0 0;
      border-top: none;
    }
  }

  @each $type in 'lines', 'boxes' {
    &.#{$type} {
      .contentList {
        .#{$type} {
          display: block;
        }
      }
    }
  }

  @include mobile {
    &.lines,
    &.boxes {
      .settings {
        @include border-radius(8px);
        border: 2px solid var(--vkit-color-contrast-2);
        margin: 0 0 16px;
      }

      .contentList {
        border-top: none;
        margin: auto;
        width: auto;

        &:not(:first-child) {
          border-top: none;
        }

        .lines {
          display: none;
        }

        .boxes {
          display: block;
        }

        .footer {
          padding-bottom: 0;
          border-top: none;
        }
      }
    }
  }
}
