@import '@/theme/variables.scss';

.iconBox {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  background-color: rgba(0, 123, 255, 0.1);

  &.transparent {
    background-color: transparent;
  }

  &.circle {
    border-radius: 50%;
  }

  @each $name, $value in $colorsDefaultMarkup {
    &.#{$name} {
      background-color: $value;
    }
  }

  .iconImage {
    background-color: var(--vkit-color-default);

    @each $name, $value in $colorsDefaultMarkupTint {
      &.#{$name} {
        background-color: $value;
      }
    }
  }
}
