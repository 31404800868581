@import '../../../theme/autoprefixer.scss';

.buttonCard {
  @include border-radius(9px);
  @include transition(border-color 0.3s);
  @include flex-direction(column);
  border: 2px solid var(--vkit-color-contrast-3);
  box-sizing: border-box;
  cursor: pointer;
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  min-height: 180px;
  max-width: calc(25% - 24px);
  margin: 0 24px 24px 0;
  padding: 8px;

  &:hover {
    border-color: var(--vkit-color-default);
  }

  @include mediumScreen {
    max-width: calc(50% - 24px);
  }

  @include mobile {
    margin-bottom: 32px;
    max-width: 100%;
    min-height: 180px;
    padding: 8px;
  }
}
