.emptyMessage {
  .image {
    align-items: center;
    background: center center / 70% no-repeat;
    display: flex;
    height: 120px;
    justify-content: center;
    min-width: 120px;
  }

  .content {
    display: flex;
    max-width: 600px;
    margin: 16px;
  }

  &.vertical {
    .content {
      flex-direction: column;
      align-items: center;
      max-width: 400px;
    }
  }
}
