@import '../../../theme/variables';
@import '../../../theme/autoprefixer';

.iconBenefit {
  @include border-radius(8px);
  align-items: center;
  display: flex;
  height: 48px;
  justify-content: center;
  margin-right: 20px;
  min-width: 48px;
  width: 48px;

  &.small {
    height: 40px;
    min-width: 40px;
    width: 40px;
    margin-right: 0;
  }

  &.default {
    background-color: $colorDefaultOpacity;
    svg {
      fill: $colorDefault;
    }
  }

  @each $name, $value in $colorsDefaultMarkup {
    &.#{$name} {
      background-color: $value;
    }
  }
}
