@import '@/theme/variables.scss';
@import '@/theme/autoprefixer.scss';

.button {
  background: unset;

  &:hover {
    cursor: pointer;
  }

  &.default,
  &.center {
    text-align: center;
  }

  &.left {
    text-align: left;
  }

  &.right {
    text-align: right;
  }

  p {
    color: darken($colorDefault, 10%);
    font-weight: bold;
    text-decoration: underline;
  }
}
