@import '../../theme/variables.scss';
@import '../../theme/autoprefixer.scss';

.main {
  width: 100%;

  .header {
    box-sizing: border-box;
    border-bottom: 1px solid var(--vkit-color-contrast-2);
    position: relative;
    z-index: 4;

    .content {
      padding: 16px 24px;

      &.mobile {
        padding: 50px 16px 24px;
      }
    }

    @each $component in 'select', 'textField' {
      [class*='#{$component}_#{$component}'] {
        [class^='#{$component}_container'] {
          [class^='#{$component}_field'] {
            min-height: 38px;
            padding-left: 12px;
          }
        }

        [class*='#{$component}_header']:empty {
          display: none;
        }
      }
    }

    [class*='select_select'] {
      [class^='select_iconSelect'] {
        margin-right: 8px;
      }
      [class*='search_header']:empty {
        display: none;
      }
    }
  }

  .content {
    margin: 0 auto;
    max-width: 1366px;
    box-sizing: border-box;
    padding-bottom: 100px;
  }
}
