@import '../../../theme/autoprefixer';

.textData {
  display: flex;
  margin: 0 -19px -19px 0;
  flex-wrap: wrap;

  .textItemData {
    margin: 0 19px 19px 0;
    width: calc(100% - 19px);
    max-width: calc(33.33% - 19px);

    [class*='box_box'],
    [class*='box_box'] [class*='box_wrapper'] {
      background-color: transparent;
    }

    p {
      word-break: break-word;
    }

    @include mediumScreen {
      max-width: calc(50% - 19px);
    }

    @include mobile {
      max-width: calc(100% - 19px);
    }
  }
}
