@import '../../../../theme/variables.scss';
@import '../../../../theme/utils/color.scss';

.menu {
  .groupNameBox {
    height: 52px;
    display: flex;
    align-items: center;

    .groupName {
      color: var(--vkit-color-contrast-9);
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
      padding-left: 12px;
    }
  }

  .menuItem {
    border-left: 4px solid transparent;
    background: transparent;
    display: flex;
    width: 100%;
    padding: 16px 0 16px 8px;
    transition: all 0.4s ease-in-out;
    gap: 8px;
    align-items: center;
    text-decoration: none;
    font-size: 14px;

    .menuItemIcon {
      .customImage {
        display: flex;
        width: 20px;
        height: 20px;
        background-color: color(light, $colorsDefaultMarkupTint);
      }

      svg {
        width: 20px;
        height: 20px;
        fill: color(light, $colorsDefaultMarkupTint);
      }
    }

    .menuItemLabel {
      color: var(--vkit-color-contrast-9);
      overflow: hidden;
      width: 0;
      transition: all 0.5s ease-in-out;
      white-space: nowrap;
    }

    &.isOpenMenuItem {
      .menuItemLabel {
        width: 100%;
      }
    }

    &:hover,
    .active {
      text-decoration: none;
      background: rgba($colorDefault, 0.1);
      .menuItemIcon {
        .customImage {
          background-color: var(--vkit-color-default);
        }
        svg {
          fill: var(--vkit-color-default);
        }
      }
      .menuItemLabel {
        color: var(--vkit-color-default);
      }
    }

    &.active {
      border-left-color: var(--vkit-color-default);
      background: rgba($colorDefault, 0.1);
      &:hover {
        cursor: default;
      }
      .menuItemIcon {
        .customImage {
          background-color: var(--vkit-color-default);
        }
        svg {
          fill: var(--vkit-color-default);
        }
      }
      .menuItemLabel {
        color: var(--vkit-color-default);
      }
    }
  }

  .menuItemDisabled {
    .menuItem,
    .menuItem:hover {
      cursor: not-allowed;
      background: none;
      .menuItemIcon {
        .customImage {
          background-color: var(--vkit-color-contrast-5);
        }
        svg {
          fill: var(--vkit-color-contrast-5);
        }
      }
      .menuItemLabel,
      .menuItemLabel:hover {
        color: var(--vkit-color-contrast-5);
      }
    }
  }

  .divider {
    border-bottom: 1px solid var(--vkit-color-contrast-5);
    width: 100%;
    margin: 0 8px;
  }
}
