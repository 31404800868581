@import './autoprefixer.scss';

a {
  color: var(--vkit-color-default);
  text-decoration: none;

  &:hover,
  &:focus {
    text-decoration: underline;
  }
}

[class*='dialog_dialog'] {
  [class*='dialog_body'] {
    min-height: auto;
  }
}

[class*='select_select'] {
  [class^='select_iconSelect'] {
    margin-right: 8px;
  }
  [class*='search_header']:empty {
    display: none;
  }
}

iframe:not([src]) {
  display: none;
}

b {
  font-family: 'Ubuntu-bold', sans-serif;
}
