@import '../../../../../theme/main.scss';
@import '../../../../../theme/utils/color.scss';
@import '../../../../../theme/variables.scss';

.buttonActionsWrapper {
  @include border-radius(6px);
  background: var(--vkit-color-contrast-2);
  display: flex;
  flex-direction: column;
  position: relative;

  .buttonActions,
  .buttonActionOptions {
    @include border-radius(6px 0 0 6px);
    align-items: center;
    background: var(--vkit-color-contrast-1);
    border: none;
    cursor: pointer;
    display: flex;
    font-family: 'Ubuntu-regular', sans-serif;
    justify-content: center;
    letter-spacing: 0.03rem;
    outline: none;
    overflow: hidden;
    position: relative;
    text-decoration: none;
    text-overflow: ellipsis;
    user-select: none;
    white-space: nowrap;
    color: var(--vkit-color-contrast-9);

    &:last-child {
      @include border-radius(6px);
    }

    &.pointerEventNone {
      pointer-events: none;
    }
  }

  $sizes: 'minor' 'small' 'medium' 'larger' 'xLarger';
  .buttonActions {
    .loader {
      margin-right: 8px;
    }

    .text {
      overflow: hidden;
      position: relative;
      text-overflow: ellipsis;

      &.bold {
        font-family: 'Ubuntu-bold', sans-serif;
      }
    }

    @for $i from 1 through length($sizes) {
      &.#{nth($sizes, $i)} {
        padding: #{$i * 2}px #{$i * 4}px;

        @if $i == 1 {
          $size: 8px;
          font-size: $size;

          svg,
          .loader > div {
            width: $size;
            height: 16px;
          }

          .loader {
            @include transform(scale(0.25));
          }
        } @else if $i == 2 {
          $size: 12px;
          font-size: $size;

          svg,
          .loader > div {
            width: $size;
            height: $size;
          }

          .loader {
            @include transform(scale(0.4));
          }
        } @else {
          $size: #{$i * 4.8}px;
          font-size: $size;

          svg,
          .loader > div {
            width: $size;
            height: $size;
          }

          .loader {
            @include transform(scale(0.5));
          }
        }
      }
    }
  }

  .buttonActionOptions {
    @include transition(transform 0.3s ease);
    background: transparent;

    &.flip {
      @include transform(rotate(180deg));
    }

    svg {
      fill: var(--vkit-color-contrast-9);
    }

    @for $i from 1 through length($sizes) {
      &.#{nth($sizes, $i)} {
        padding: #{$i}px;
      }
    }
  }

  .buttonActionOption {
    background: transparent;
    cursor: pointer;
    display: flex;
    padding: 8px;
    text-align: left;
    white-space: break-spaces;
    width: 100%;

    .iconBox {
      display: flex;
      align-items: center;
      justify-content: center;
      min-width: 30px;
      height: 30px;
      margin-right: 8px;
      border-radius: 8px;
      background-color: rgba(0, 123, 255, 0.1);
    }

    &:hover {
      background: var(--vkit-color-contrast-1);
    }
  }

  .boxFloatLayer {
    min-width: 220px;
    position: fixed;
    z-index: 4;
  }

  &.noPrincipalClick {
    background: var(--vkit-color-contrast-2);

    .buttonActions {
      background: transparent;
      padding-right: 0;
    }

    .buttonActionOptions {
      svg {
        fill: var(--vkit-color-contrast-9);
      }
    }
  }

  @each $name, $value in $colorsDefaultMarkup {
    &.#{$name} {
      background: darken(color($name, $colorsDefaultMarkupTint), 20%);

      &:hover {
        background: darken(color($name, $colorsDefaultMarkupTint), 30%);
      }

      .buttonActions {
        background: color($name, $colorsDefaultMarkupTint);
        color: $colorLightContrast;
      }

      .buttonActionOptions {
        svg {
          fill: $colorLightContrast;
        }
      }

      &.noPrincipalClick {
        background: color($name, $colorsDefaultMarkupTint);

        .buttonActions {
          background: transparent;
        }
      }

      &.invertColor {
        background: $value;

        .buttonActions {
          background: $value;
          color: color($name, $colorsDefaultMarkupTint);
        }

        .buttonActionOptions {
          svg {
            fill: color($name, $colorsDefaultMarkupTint);
          }
        }

        &:hover {
          background-color: darken($value, 10%);
        }
      }
    }
  }

  &.default {
    background: var(--vkit-color-default-5);

    .buttonActions {
      background: var(--vkit-color-default);
      color: $colorLightContrast;
    }

    .buttonActionOptions {
      svg {
        fill: $colorLightContrast;
      }
    }

    &:hover {
      background-color: var(--vkit-color-default-4);
    }

    &.invertColor {
      background: $colorDefaultOpacity;

      .buttonActions {
        background: $colorDefaultOpacity;
        color: var(--vkit-color-default-2);
      }

      .buttonActionOptions {
        svg {
          fill: var(--vkit-color-default-2);
        }
      }

      &:hover {
        background-color: darken($colorDefaultOpacity, 10%);
      }

      &.noPrincipalClick {
        .buttonActions {
          background: transparent;
        }
      }
    }
  }
}

body[data-theme='dark'] {
  .buttonActionsWrapper {
    .buttonActionOption {
      &:hover {
        background: var(--vkit-color-contrast-2);
      }
    }
  }
}
