@import '@/theme/variables.scss';
@import '@/theme/main.scss';

.fileUpload {
  min-height: 138px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 16px 0;

  @each $key, $value in $colorsDefaultMarkupTint {
    &.#{$key} {
      border: 1px solid $value;
      box-shadow: 0px 0px 0px 1px $value;
      border-radius: 6px;
    }
  }
}
