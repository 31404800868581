@import '../../../theme/autoprefixer.scss';

.showHide {
  @include transition(opacity 0.3s ease, height 0.3s ease);
  display: block;
  height: 0;
  opacity: 0;
  position: relative;
  width: 100%;

  &.absolute {
    position: absolute;
  }

  > .contentShowHide {
    @include transition(transform 0.3s ease);
    display: block;
    width: 100%;
  }

  @mixin neutralPosition {
    &.visible {
      opacity: 1;

      > .contentShowHide {
        @include transform(none);
      }
    }
  }

  &.slideToLeft {
    > .contentShowHide {
      @include transform(translateX(-10%));
    }

    &:last-of-type {
      > .contentShowHide {
        @include transform(translateX(10%));
      }
    }

    @include neutralPosition;
  }

  &.slideToDown {
    > .contentShowHide {
      @include transform(translateY(-30px));
    }

    &:last-of-type {
      > .contentShowHide {
        @include transform(translateY(30px));
      }
    }

    @include neutralPosition;
  }

  &.fade {
    @include transition(opacity 0);
    @include neutralPosition;
  }
}
